import DpFormMixin from 'src/core/components/DpForm/DpFormMixin.js'
import DpForm from 'src/core/components/DpForm/DpForm2.vue'
import CorModel from 'src/model/veiculo/CorModel.js'
import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'
import IcarrosCorModel from 'src/model/veiculo/IcarrosCorModel'
import WebmotorsCorModel from 'src/model/veiculo/WebmotorsCorModel'
import UsadosbrCorModel from 'src/model/veiculo/UsadosbrCorModel'
import OlxCorModel from 'src/model/veiculo/OlxCorModel'
import MobiautoCorModel from 'src/model/veiculo/MobiautoCorModel'

export default {
  name: 'CorForm',
  components: { DpForm },
  mixins: [DpFormMixin, NotificacaoMixin],
  data () {
    return {
      codigos_icarros: [],
      codigos_webmotors: [],
      codigos_usadosbr: [],
      codigos_olx: [],
      codigos_mobiauto: [],
      modelPadrao: new CorModel(),
      modelIcarrosCor: new IcarrosCorModel(),
      modelWebmotorsCor: new WebmotorsCorModel(),
      modelUsadosbrCor: new UsadosbrCorModel(),
      modelOlxCor: new OlxCorModel(),
      modelMobiautoCor: new MobiautoCorModel()
    }
  },
  mounted () {
    this.modelIcarrosCor.getListagem().then((response) => {
      this.codigos_icarros = response.dados ?? []
    }).catch((error) => {
      this.notificacao('erro', error.msg)
    })
    this.modelMobiautoCor.getListagem().then((response) => {
      this.codigos_mobiauto = response.dados ?? []
    }).catch((error) => {
      this.notificacao('erro', error.msg)
    })
    this.modelWebmotorsCor.getListagem().then((response) => {
      this.codigos_webmotors = response.dados ?? []
    }).catch((error) => {
      this.notificacao('erro', error.msg)
    })
    this.modelUsadosbrCor.getListagem().then((response) => {
      this.codigos_usadosbr = response.dados ?? []
    }).catch((error) => {
      this.notificacao('erro', error.msg)
    })
    this.modelOlxCor.getListagem().then((response) => {
      this.codigos_olx = response.dados ?? []
    }).catch((error) => {
      this.notificacao('erro', error.msg)
    })
  },
  methods: {

  }
}
