import PadraoModel from 'src/core/model/PadraoModel.js'

export default class WebmotorsCorModel extends PadraoModel {
  recurso = 'webmotors/cores';
  microServico = 'automotor';
  include = null;
  searchFixo = null;
  nome = 'Cores Webmotors';

  colunasGrid = [
  ];

  form = {
    CodigoCor: null,
    Descricao: null
  };

  constructor () {
    super()
    this.formLimpo = Object.assign({}, this.form)
  }
}
